import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import {
  ServerTable,
  // ClientTable,
  // EventBus
} from "v-tables-3";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Multiselect from "vue-multiselect";
// Add font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faQrcode,
  faExpand,
  faRotateRight,
  faGear,
  faEye,
  faPrint,
  faArrowsRotate,
  faChevronRight,
  faEllipsisVertical,
  faBars,
  faSliders,
  faClock,
  faCircleXmark,
  faCircleExclamation,
  faCircleInfo,
  faArrowRightArrowLeft,
  faLinkSlash,
  faArrowsSpin,
  faArrowDown,
  faUserLarge as faUsers,
  faCircle,
  faPlus,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import {
  faUser,
  faTrashCan,
  faPaste,
  faEye as faEyes,
} from "@fortawesome/free-regular-svg-icons";

library.add([
  faUsers,
  faQrcode,
  faExpand,
  faUser,
  faRotateRight,
  faGear,
  faEye,
  faPrint,
  faTrashCan,
  faArrowsRotate,
  faChevronRight,
  faEllipsisVertical,
  faBars,
  faSliders,
  faClock,
  faCircleXmark,
  faCircleExclamation,
  faCircleInfo,
  faPaste,
  faLinkSlash,
  faArrowRightArrowLeft,
  faArrowsSpin,
  faArrowDown,
  faCircle,
  faPlus,
  faExternalLink,
  faEyes,
]);

import emitter from "@/services/mitt";
import ModalComponent from '@/services/modal-service'
import "vue-multiselect/dist/vue-multiselect.css";
import "@/assets/css/main.css";
import QrReader from "vue3-qr-reader";
import { setupI18n } from "./i18n";
import en from "./locales/en.json";
import en_dokter from "./locales/en-dokter.json";
import VueHtmlToPaper from "vue-html-to-paper";

const i18n = setupI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: ["en", "en_dokter"],
  messages: {
    en,
    en_dokter,
  },
});

const printHtmlConfig = {
  name: "_blank",
  specs: [ "titlebar=yes", "scrollbars=yes"],
  styles: [
      'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css',
      'https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap'
  ],
};
import { io } from "socket.io-client";
import "@fontsource/montserrat";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/inconsolata";
import "@fontsource/inconsolata/500.css";
import "@fontsource/inconsolata/600.css";
import "@fontsource/inconsolata/700.css";
// import MarqueeText from "vue-marquee-text-component";
import { inspect } from "@xstate/inspect";

import Keycloak from "keycloak-js";
let initOptions = {
  url: process.env.VUE_APP_SSO_LINK,
  realm: process.env.VUE_APP_SSO_REALM,
  clientId: process.env.VUE_APP_SSO_CLIENT_ID,
};

window.keycloak = new Keycloak(initOptions);

const token = localStorage.getItem("kc_token");
const refreshToken = localStorage.getItem("kc_refreshToken");
// window.window.keycloak
window.keycloak
  .init({ onLoad: "login-required", token, refreshToken })
  .then((success) => {
    localStorage.removeItem('dokterId')
    // window.keycloak.onTokenExpired = () => {
    //   window.keycloak.updateToken(-1)
    //     .then(() => {
    //       localStorage.setItem("kc_token", window.keycloak.token);
    //       localStorage.setItem("kc_refreshToken", window.keycloak.refreshToken);
    //     }).catch(()=> {
    //       console.log('token remove', new Date())
    //       localStorage.removeItem("kc_token");
    //       localStorage.removeItem("kc_refreshToken");
    //     })
    // }
    if (success) {
      // window.keycloak.onTokenExpired()
      localStorage.setItem("kc_token", window.keycloak.token);
      localStorage.setItem("kc_refreshToken", window.keycloak.refreshToken);
      //load your app from here
      window.socket = io(process.env.VUE_APP_SOCKET_URL);
  
      const app = createApp(App)
        .use(store)
        .use(router)
        .use(QrReader)
        .use(VueHtmlToPaper, printHtmlConfig)
        .component("font-awesome-icon", FontAwesomeIcon)
        // .component("marquee-text", MarqueeText)
        .component("multiselect", Multiselect);
  
      app.use(ServerTable, {}, "bootstrap4");
  
      inspect({
        iframe: false,
      });

      
      app.config.globalProperties.$Modal = ModalComponent
      app.config.globalProperties.emitter = emitter;
      app.use(VueSweetalert2);
      app.use(i18n);
      app.mount("#app");
    }

  }).catch(_err => {
    console.log(_err)
    console.log(_err.response)
  });