import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../page/Home.vue'
import CheckUpdateGroup from '@/services/CheckUpdateGroup'
import ENUM_ROUTER from '@/ENUM/router'
import ENUM_PERMISSION from '@/ENUM/permission'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/device-config',
    name: 'deviceConfig',
    component: () => import('@/page/DeviceConfigPage.vue')
  },
  {
    path: '/patient-officer-experience',
    name: 'peo',
    component: () => import('@/page/PatientExperienceOfficerPage.vue')
  },
  {
    path: '/cluster-caller',
    component: () => import('@/page/ClusterCallerPage.vue'),
    children: [
      {
        path: '',
        name: 'clusterCallerDekstop',
        component: () => import('@/views/ClusterCaller/ProviderClusterCallerScreenDekstop.vue')
      },
      {
        path: 'mobile',
        component: () => import('@/views/ClusterCaller/ClusterCallerMobileGlobal.vue'),
        name: 'clusterMobileCaller'
      },
    ]
  },
  {
          path: '/qr-scan',
          name: 'QrScan',
          component: () => import('@/views/ClusterCaller/mobile/ScanQr/ProviderScanQr.vue')
  },
  {
    path: '/reminder',
    name: 'reminderPatient',
    component: () => import('@/page/ReminderPatient.vue')
  },
  {
    path: '/reminder-dekstop',
    name: 'reminderPatientDekstop',
    component: () => import('@/page/ConfirmNextAppoint.vue')
  },
  {
    path: '/error-camera',
    name: 'errorCamera',
    component: () => import('@/page/ErrorCamera.vue')
  },
  {
    path: '/manual-registration',
    name: 'manualRegistration',
    component: () => import('@/page/ManualRegistration.vue')
  },
  {
    path: '/unit-caller',
    name: ENUM_ROUTER.UNIT_CALLER,
    component: () => import('@/page/UnitCallerProvider.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  await CheckUpdateGroup.updateGroupUser()
    .then(() => {
      if (to.name == ENUM_ROUTER.UNIT_CALLER) {
        const isGroup = CheckUpdateGroup.checkPermissonGroup(ENUM_PERMISSION.PAGE_UNIT_CALLER)
        if (isGroup) next()
        else next('/')
        return
      } else if (to.name == ENUM_ROUTER.DOKTER_CALLER_MOBILE || to.name == ENUM_ROUTER.DOKTER_CALLER_DEKSTOP) {
        const isGroup = CheckUpdateGroup.checkPermissonGroup(ENUM_PERMISSION.PAGE_DOKTER_CALLER)
        if (isGroup) next()
        else next('/')
        return
      } else if (to.name == ENUM_ROUTER.PEO) {
        const isGroup = CheckUpdateGroup.checkPermissonGroup(ENUM_PERMISSION.PAGE_PEO)
        if(isGroup) next ()
        else next('/')
        return
      } else if (to.name == ENUM_ROUTER.DEVICE_CONFIG) {
        const isGroup = CheckUpdateGroup.checkPermissonGroup(ENUM_PERMISSION.PAGE_DEVICE_CONFIG)
        if(isGroup) next()
        else next('/')
        return
      } else if(to.name == ENUM_ROUTER.HOME) {
        // const isHaveHas = from.hash && from.hash.includes('#')
        // console.log(from)
        const page = [ENUM_PERMISSION.PAGE_UNIT_CALLER, ENUM_PERMISSION.PAGE_DOKTER_CALLER, ENUM_PERMISSION.PAGE_PEO, ENUM_PERMISSION.PAGE_DEVICE_CONFIG]
        const allPage = page.filter(_item => CheckUpdateGroup.checkPermissonGroup(_item))
        // if(allPage.length == 1 && isHaveHas) {
        if(allPage.length == 1) {
          const isMobile = window.innerWidth <= 800 ? true : false
          const nameRoute = CheckUpdateGroup.routingPage(allPage[0], isMobile)
          next({ name: nameRoute})
        }
      }
      next()
      return
    })
})

export default router
