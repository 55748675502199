import moment from 'moment'
export default {
    convertMinuteToText (time) {
        const hour = Math.floor(time / 60)
        const minute = Math.floor(time % 60)
        const textHour = `${hour > 0 ? hour.toString().padStart(2, '0') + ' jam' : ''} ${minute.toString().padStart(2, '0')} menit`
        return textHour
    },
    convertDiffTime(nowTime, waitingTime) {
        const waiting = moment(waitingTime).format('x')
        const diffTime = Math.ceil((parseInt(nowTime) - parseInt(waiting))/ 1000)
        const hour = Math.floor(diffTime / 3600)
        const minute = diffTime > 0 ? Math.floor((diffTime - (hour * 3600)) / 60) : 0
        const textHour = `${hour > 0 ? hour.toString().padStart(2, '0') + 'jam' : ''} ${minute.toString().padStart(2, '0')}menit`
        return textHour
    },
    convertDiffTimeHTML (nowTime, waitingTime) {
        const waiting = moment(waitingTime).format('x')
        let nowTimeX = nowTime
        if (typeof nowTime != Number) {
            nowTimeX = moment(nowTime).format('x')
        }
        const diffTime = Math.ceil((parseInt(nowTimeX) - parseInt(waiting))/ 1000)
        const hour = Math.floor(diffTime / 3600)
        const minute = diffTime > 0 ? Math.floor((diffTime - (hour * 3600)) / 60) : 0
        const textHour = `${hour > 0 ? '<span class="fw-700">'+ hour.toString().padStart(2, '0') + '</span>jam' : ''} <span class="fw-700">${minute.toString().padStart(2, '0')}</span>menit`
        return textHour
    },
    convertTime (time, format) {
        if (time) {
            return moment(time).format(format)
        }
        return '-'
    },
    timeMilisHour(data) {
        if(!data) return 0
        const timeSplit = data.split(':')
        const secondTime = parseInt(timeSplit[0]) * 3600 + parseInt(timeSplit[1]) * 60 + parseInt(timeSplit[2]) 
        return secondTime
    },
    convertDayWithId (id) {
        if(id == '0') return 'Senin'
        else if(id == '1') return 'Selasa'
        else if(id == '2') return 'Rabu'
        else if(id == '3') return 'Kamis'
        else if(id == '4') return 'Jumat'
        else if(id == '5') return 'Sabtu'
        else if (id == '6') return 'Minggu'
        else return '-'
    },
    checkIsLateTime(checkInTime, appointment) {
        let isLate = false
        const dateNow = moment().format('DD/MM/YYYY')
        const timeAppointment = `${dateNow} ${appointment}`
        const ms = moment(checkInTime).diff(moment(timeAppointment, 'DD/MM/YYYY HH:mm:ss'))
        const d = moment.duration(ms)
        isLate = d._milliseconds > 0
        return isLate
    },
    minutesToHours(duration) {
        let hour = ''
        let minute = ''
        if (duration) {
            hour = Math.floor(duration / 60)
            minute = duration % 60
        }
        return {
            hour,
            minute
        }
    },
    timeDiffFormat(jamMulai, jamSelesai) {
        const mulai = jamMulai ? jamMulai.split(":") : 0;
        const selesai = jamSelesai ? jamSelesai.split(':') : 0;
        if (mulai == 0) return 0
        const timeXMulai = (parseInt(mulai[0]) * 60) + parseInt(mulai[1])
        const timeXSelesai = (parseInt(selesai[0]) * 60) + parseInt(selesai[1])
        return (timeXSelesai - timeXMulai) * 60
    },
    countDownTimerToText(now, updateTime) {
        const diffTime = Math.floor((updateTime - now) / 1000)
        const hour = Math.floor(diffTime / 3600)
        const minute = Math.floor((diffTime - (hour * 3600)) / 60)
        const second = diffTime % 60
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
    },
    sameDayMoment(date1, date2) {
        const day1 = moment(date1)
        const day2 = moment(date2)
        return day1.date() === day2 .date() && day1.month() === day2.month()
    },
    addTimeFrom(time, addNumber, addFormat, format) {
        return moment(time).add(addNumber, addFormat).format(format)
    },
    funcIsSameDay (now, dataTime) {
        const dateNowStr = moment(now).format('DD-MM-YYYY')
        const dataTimeStr = moment(dataTime).format('DD-MM-YYYY')
        if(dateNowStr === dataTimeStr) return true
        return false
    }
}