<template>
  <div class="card-auto-header">
        <button class="btn caller-btn-white mx-2" @click="accountProfile"><font-awesome-icon icon="fa-regular fa-user" /></button>
        <button class="btn caller-btn-white mx-2" @click="fullscreen"><font-awesome-icon icon="fa-solid fa-expand" /></button>
  </div>
  <div class="card-name">
    <label class="c-primary fw-600 d-block">Selamat datang, {{userName}} !</label>
    <label class="c-gray fw-400">Silakan pilih tipe Caller berikut</label>
  </div>
  <div class="center-card text-center">
    <button class="btn caller-btn text-uppercase fw-700 fs-14 d-block mx-auto  mb-3" @click="$router.push({name: 'unitCaller'})">Caller unit</button>
    <button class="btn caller-btn text-uppercase fw-700 fs-14 d-block mx-auto mb-3" @click="goToCallerDokter">Caller Cluster</button>
    <button class="btn caller-btn text-uppercase fw-700 fs-14 d-block mx-auto mb-3" @click="$router.push({name: 'peo'})">PEO</button>
    <button class="btn caller-btn text-uppercase fw-700 fs-14 d-block mx-auto mb-3" @click="$router.push({name: 'deviceConfig'})">Device Management</button>
  </div>
</template>

<script>

export default {
     name: 'HomeView',
     data () {
        return {
            userName: localStorage.name_user ?? '-'
        }
     },
     methods: {
        goToCallerDokter () {
            const isMobile = window.innerWidth <= 800 ? true : false
            if(isMobile) {
                this.$router.push({name: 'clusterMobileCaller'})
            } else {
                this.$router.push({name: 'clusterCallerDekstop'})
            }
        },
        accountProfile () {
            const url = process.env.VUE_APP_SSO_LINK + '/realms'
            const accountProfile = `${url}/${process.env.VUE_APP_SSO_REALM}/account`
            window.open(accountProfile, '_blank')
        },
        fullscreen () {
            var elem = document.documentElement
            if (!document.fullscreen) {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }
            }
        }
     },
     mounted () {
        const that = this
        window.addEventListener('storage', function (event) {
        if (event.key === "name_user") {
            that.userName = event.newValue
            // localStorage.removeItem("buttonClicked");
        }
      });
     }
}
</script>

<style>

</style>