/* eslint-disable no-useless-escape */
import TimerService from '@/services/TimerService'
export default {
    objToArrayData(str) {
      if(!str) return []
      const closeBrace = str.slice(1, -1)
      const arrData = closeBrace.split(',')
      return arrData
    },
    
    getVarData(textData) {
        const arrayMatch = textData.match(/\{[a-zA-Z0-9\.\-]+\}/g)
        return arrayMatch ? arrayMatch : [];
      },
      getInObjectKey(keyOri, data) {
        let key = keyOri.replace('{', '').replace('}', '')
        const arrayKey = key.split('.')
        const dataReduce = arrayKey.reduce((prev, next) => {
          let nextKey = next
          prev = prev[nextKey]
          if(prev) return prev
          return {}
        }, data)
        if (typeof dataReduce == 'object') {
          return keyOri
        }
        return dataReduce
      },
      setMessage(objData, message) {
        return new Promise((resolve) => {
            let objReservasi = objData
            const listPerubahan = this.getVarData(message)
            let dataMessage = message
            
            listPerubahan.forEach(element => {
              dataMessage = dataMessage.replace(element, this.getInObjectKey(element, objReservasi))
            });
             dataMessage = dataMessage.replaceAll('&', ' ')
             resolve(dataMessage)
        })
      },
      parseAtob(token) {
        const tokenParse = token.split('.')
        if (tokenParse.length > 0) {
          return JSON.parse(atob(tokenParse[1]))
        }
        return {}
      },
      addSessionTemplate () {
        let toast = []
        let labelToastShow = ''
        toast = ['fs-20', 'fw-600' ]
        labelToastShow = 'label-toast-show'
        if (!document.getElementById('toast-error')) {
          const bodyTag = document.body
          const element = document.createElement('div')
          const classList = ['bg-white', 'toast-end-session', 'label-toast']
          element.classList.add(...classList)
          element.id = 'toast-error'


          const elementBody =  document.createElement('div')
          const elementBodyClass = ['row', 'gx-2', 'mb-6']
          elementBody.classList.add(...elementBodyClass)

          const col4 = document.createElement('div')
          const col4Class = ['col-3', 'image-information']
          col4.classList.add(...col4Class)

          const col8 = document.createElement('div')
          const col8Class = ['col-9']
          col8.classList.add(...col8Class)

          const element1 = document.createElement('div')
          const classList1 = [...toast, 'c-primary']
          element1.classList.add(...classList1)
          element1.textContent = 'Sesi berakhir'
          
          const element2 = document.createElement('div')
          const classList2 = ['text-toast-body', 'lh-normal']
          element2.classList.add(...classList2)
          element2.innerHTML = `Anda sudah tidak aktif lebih dari 30 menit, silakan <span class="fw-600">Sign In</span> ulang `

          const element3 = document.createElement('button')
          const classList3 = ['caller-btn', 'float-end', 'fs-14', 'fw-700']
          element3.classList.add(...classList3)
          element3.textContent = 'Sign in'
          element3.id = 'click-toast-sign'

          col8.appendChild(element1)
          col8.appendChild(element2)
          elementBody.appendChild(col4)
          elementBody.appendChild(col8)
          element.appendChild(elementBody)
          element.appendChild(element3)
          // element.textContent = 'Session Expired'
          bodyTag.appendChild(element)
          setTimeout(() => {
            document.getElementById('toast-error').classList.add(labelToastShow)
          }, 100)
          const that = this
          if(element.addEventListener) {
            element.addEventListener("click", function() {
              that.removeSessionAlert()
            });
          } else { 
            element.attachEvent("onclick", function() {
              that.removeSessionAlert()
            })
          }
        }
      },
      removeSessionAlert () {
        if (document.getElementById('click-toast-sign')) {
          const labelToastShow = 'label-toast-show'
            document.getElementById('toast-error').classList.remove(labelToastShow)
            setTimeout(() => {
              document.getElementById('toast-error').remove()
              window.keycloak.logout()
            }, 2000)
        }
      },
      mapperJadwalNoteDelay (delayJadwal, jadwalUnit ) {
        return delayJadwal
            .map(_item2 => {
                const dataJadwal = jadwalUnit.find(_item3 => _item3.id == _item2.jadwalId)
                return {
                    jamMulai: dataJadwal.jamMulai ? dataJadwal.jamMulai.slice(0, 5) : '',
                    jamSelesai: dataJadwal.jamSelesai ? dataJadwal.jamSelesai.slice(0, 5) : '',
                    timeText: TimerService.convertMinuteToText(_item2.durationInMinute)
                }
            })
            .sort((_itemA, _itemB) => _itemA.jamMulai.localeCompare(_itemB.jamMulai))
            .reduce((prev, next) => {
                if (next.jamMulai && !prev) {
                    prev += `DELAY ${next.timeText} (${next.jamMulai}-${next.jamSelesai})`
                } else if (next.jamMulai) {
                    prev += `, ${next.timeText} (${next.jamMulai}-${next.jamSelesai})`
                }
                return prev
            }, '')  
    },
    splitJoin(data, addStr) {
      const strRoom = data.toString().split(',')
      strRoom.push(addStr)
      return strRoom.join(',')
    },
    splitJoinWithFilter(data, haveStr) {
      const strRoom = data.toString().split(',')
      const afterFilter = strRoom.filter(_item => _item != haveStr)
      return afterFilter.join(',')
    }

}