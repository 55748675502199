import { nextTick, isRef } from 'vue'
import { createI18n } from 'vue-i18n'


export const SUPPORT_LOCALES = ['en', 'en-dokter']

function isComposer(
  instance,
  mode
) {
  return mode === 'composition' && isRef(instance.locale)
}

export function getLocale(i18n) {
  if (isComposer(i18n.global, i18n.mode)) {
    return i18n.global.locale.value
  } else {
    return i18n.global.locale
  }
}

export function setLocale(i18n, locale) {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale
  } else {
    i18n.global.locale = locale
  }
}

export function setupI18n(options= { locale: 'en' }){
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale){
  setLocale(i18n, locale)
    if (  document.querySelector('html')) {
        document.querySelector('html').setAttribute('lang', locale)
    }
}

const getResourceMessages = (r) => r.default || r

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages
  const messages = await import(
    /* @vite-ignore */ `./locales/${locale}.json`
  ).then(getResourceMessages)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages)

  return nextTick()
}