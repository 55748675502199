
import ENUM from '@/ENUM/router'
import ENUM_PERMISSION from '@/ENUM/permission'
export default{
    async updateGroupUser () {
        return new Promise((resolve) => {
            window.keycloak.loadUserInfo()
                .then((_data) => {
                    const userName = _data.preferred_username ?? '-'
                    if(_data.dokterId) {
                        localStorage.clear()
                        localStorage.setItem('dokterId', _data.dokterId)
                    }
                    localStorage.setItem('name_user', userName)
                    const data = _data.permissions
                    localStorage.setItem('groupPermission', JSON.stringify(data))
                    resolve(true)
                }).catch(() => {
                    window.keycloak.updateToken(-1).then(() => {
                        localStorage.setItem("kc_token", window.keycloak.token);
                        localStorage.setItem("kc_refreshToken", window.keycloak.refreshToken);
                        this.updateGroupUser().then(resolve)
                    }).catch(() => window.keycloak.login())
                })
        })
    },
    includesItem (text) {
        const regexText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex =  new RegExp(regexText);
        return regex
    },
    checkPermissonGroup(item) {
        const groupPermission = localStorage.groupPermission
        const parseGroupPermission = JSON.parse(groupPermission) ?? []
        const isPermission = parseGroupPermission.some(_item => this.includesItem(item).test(_item))
        return isPermission
    },
    routingPage(item, mobile = true) {
        if (item == ENUM_PERMISSION.PAGE_UNIT_CALLER) {
            return ENUM.UNIT_CALLER
        } else if (item == ENUM_PERMISSION.PAGE_DOKTER_CALLER) {
            if (mobile) return ENUM.DOKTER_CALLER_MOBILE
            else return ENUM.DOKTER_CALLER_DEKSTOP
        } else if(item == ENUM_PERMISSION.PAGE_PEO) {
            return ENUM.PEO
        } else if (item == ENUM_PERMISSION.PAGE_DEVICE_CONFIG) {
            return ENUM.DEVICE_CONFIG
        }
    }
}