import { Modal } from 'bootstrap'

export default {
    createModal (id) {
        return new Modal(document.getElementById(id), {
            target: `#${id}`,
            backdrop: "static",
            keyboard: false,
        });
    }
}