<template>
  <cardHome>
    <router-view />
  </cardHome>
    <label class="flexible-version fs-14 fw-600">
    {{versionSlice}}
  </label>
  <div id="printMe"></div>
</template>
<script setup>
//  import { defineAsyncComponent  } from 'vue'
  import { defineAsyncComponent, onMounted  } from 'vue'
  import helpers from './services/helpers'
  import TimerService from './services/TimerService'
  const cardHome = defineAsyncComponent(() => import("@/views/CardHome.vue"))
  const version = process.env.VUE_APP_COMMIT_ID
  const versionSlice = version.slice(0, 6)
  let selectedInterval = null

  const runTimeOut = ( timeRefreshToken ) => {
    let timeNow = 0
    selectedInterval = setInterval(() => {
      timeNow = TimerService.convertTime(new Date(), 'X')
      if (timeRefreshToken < timeNow) {
        helpers.addSessionTemplate()
        clearInterval(selectedInterval)
      }
    }, 1000)
  }
  onMounted(() => {
    if (localStorage.kc_refreshToken) {
      const refreshToken = helpers.parseAtob(localStorage.kc_refreshToken)
      runTimeOut(refreshToken.exp)
    }
    document.addEventListener('itemInserted', function () {
          if(selectedInterval) clearInterval(selectedInterval)
          helpers.removeSessionAlert()
          const refreshToken = helpers.parseAtob(localStorage.kc_refreshToken)
          runTimeOut(refreshToken.exp)
    })

    window.addEventListener('storage', function (event) {
            if(event.key == 'kc_token') {
              window.keycloak.token = event.newValue
            }
            if(event.key == 'kc_refreshToken') {
              window.keycloak.refreshToken = event.newValue
              const selectionFired = new CustomEvent('itemInserted')
              document.dispatchEvent(selectionFired);
            }
        });
  })

</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
