const ROUTER = {
    UNIT_CALLER: 'unitCaller',
    DOKTER_CALLER_MOBILE: 'clusterMobileCaller',
    DOKTER_CALLER_DEKSTOP: 'clusterCallerDekstop',
    PEO: 'peo',
    DEVICE_CONFIG: 'deviceConfig',
    HOME: 'home'
}



const ALL_DATA = {
    ...ROUTER
}

export default ALL_DATA
