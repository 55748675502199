const PERMISSION_GLOBAL = {
    PRINTER_SETUP_EDIT: 'Caller.Printer.Edit',
    REPRINTER_TICKET: 'Caller.Reprint.Enabled',
    RETURN_TO_QUEUE_ENABLED: 'Caller.ReturnToQueue.Enabled',
    PHONE_ENABLED: 'Caller.Phone.Enabled',
    WHATSAPP_ENABLED: 'Caller.Whatsapp.Enabled',
    ENABLED_PRINTER_MODAL: 'Caller.Printer.Enabled',
    ENABLED_REFRESH_PAGE: 'Caller.RefreshPage.Enabled',
    ENABLED_FULLSCREEN: 'Caller.Fullscreen.Enabled',
    ENABLED_CALLING: 'Caller.Calling.Enabled',
    ENABLED_SERVING: 'Caller.Serving.Enabled',
    ENABLED_FINISH: 'Caller.Finish.Enabled',
    ENABLED_ONPROCESS: 'Caller.OnProcess.Enabled',
    ENABLED_ONHOLD: 'Caller.OnHold.Enabled',
    ENABLED_SKIP: 'Caller.Skip.Enabled',
    ENABLED_ONCONSUL: 'Caller.OnConsul.Enabled',
    ENABLED_HIS: 'Caller.HIS.Enabled',
    ENABLED_INTERNAL_NOTE: 'Caller.InternalNote.Enabled',
    ENABLED_SCAN_QR: 'Caller.ScanQR.Enabled'
}

const PERMISSION_UNIT_CALLER = {
    // GENERAL_PERMISSION: '/SINGLE QUEUE/Unit Caller',
    // OTHER_GENERAL_PERMISSION: '/SINGLE QUEUE/Unit Caller/',
    // ADMIN_MANAGE_DESK: '/SINGLE QUEUE/Unit Caller/Admin',
    // ADMIN_MANAGE_LINE: '/SINGLE QUEUE/Unit Caller/Admin',
    // ADMIN_REMOVE_TICKET: '/SINGLE QUEUE/Unit Caller/SPV',
    // STAFF_UNIT_CALLER: '/SINGLE QUEUE/Unit Caller/STAFF',
    // SPV_UNIT_CALLER: '/SINGLE QUEUE/Unit Caller/SPV', 
    ADMIN_MANAGE_LINE: 'UnitCaller.ManageLine.View',
    ADMIN_MANAGE_COUNTER: 'UnitCaller.ManageCounter.View',
    SAVE_PATIENT_BADGE: 'Caller.PatientBadge.Save',
    SAVE_VISIT_BADGE: 'Caller.VisitBadge.Save',
    DELETE_PATIENT_BADGE: 'Caller.PatientBadge.Delete',
    DELETE_VISIT_BADGE: 'Caller.VisitBadge.Delete',
    ENABLED_DELETE_TICKET: 'UnitCaller.TicketDelete.Enabled',
    MOVE_UNIT_ENABLED: 'UnitCaller.MoveUnit.Enabled',
    SAVE_EDIT_PATIENT: 'UnitCaller.Ticket.Enabled',
    ENABLED_CREATE_TICKET: 'UnitCaller.CreateTicket.Enabled',
    CREATE_TICKET_NEW: 'UnitCaller.CreateTicket.Save',
    ENABLED_SELECT_COUNTER: 'UnitCaller.SelectCounter.Enabled',
    ENABLED_SELECT_LINE: 'UnitCaller.SelectLine.Enabled',
    ENABLED_SEARCH_PASIEN: 'UnitCaller.SearchPasien.Enabled',
    ENABLED_DETAIL_PASIEN: 'UnitCaller.DetailPasien.Enabled',
    ENABLED_BUTTON_EDIT_TIKET: 'UnitCaller.TicketButton.Enabled',
    ENABLED_SELECT_FARMASI: 'UnitCaller.SelectFarmasi.Enabled',
    SHOW_DESKRIPSI_NOTE: 'UnitCaller.DokterNote.Show',
    ENABLED_ACTIVITY_TICKET: 'UnitCaller.Activity.Enabled',
    ENABLED_BUTTON_PREPARE: 'UnitCaller.Prepare.Enabled',
    ENABLED_BUTTON_READY: 'UnitCaller.Ready.Enabled',
    ENABLED_APPOINTMENT_DELETE: 'UnitCaller.Appointment.Delete',
    ENABLED_ORDERLINE_DELETE: 'UnitCaller.Orderline.Delete'
}

const PERMISSION_DOKTER_CALLER = {
    // OTHER_GENERAL_PERMISSION_CALLER_DOKTER: '/SINGLE QUEUE/Dokter Caller/',
    // VISIT_BADGE_CALLER_DOKTER: '/SINGLE QUEUE/Dokter Caller/SPV',
    // PASIEN_BADGE_CALLER_DOKTER: '/SINGLE QUEUE/Dokter Caller/SPV',
    // STAFF_CALLER_DOKTER: '/SINGLE QUEUE/Dokter Caller/STAFF',
    SAVE_DOCTOR_NOTE: 'DoctorCaller.Note.Save',
    DELETE_DOCTOR_NOTE: 'DoctorCaller.Note.Delete',
    DELETE_CLUSTER_LIST: 'DoctorCaller.Cluster.Delete',
    SAVE_CLUSTER_LIST: 'DoctorCaller.Cluster.Save',
    MANAGE_ROOM_SETTING: 'DoctorCaller.ManageRoom.Enabled',
    MARK_PATIENT_SAVE: 'DoctorCaller.MarkPatient.Save',
    REFER_PATIENT_SAVE: 'DoctorCaller.ReferPatient.Save',
    RECHECKIN_ENABLED: 'DoctorCaller.RecheckIn.Enabled',
    ENABLED_NOTE_BUTTON: 'DoctorCaller.Note.Enabled',
    SHOW_LIST_ROOM: 'DoctorCaller.SelectedRoom.Show',
    ENABLED_FILTER_QUEUE: 'DoctorCaller.FilterQueue.Enabled',
    ENABLED_HISTORY_QUEUE: 'DoctorCaller.HistoryQueue.Enabled',
    SHOW_SELECTED_TICKET: 'DoctorCaller.SelectedTicket.Show',
    ENABLED_MARK_PATIENT: 'DoctorCaller.MarkPatient.Enabled',
    ENABLED_JOIN_TELEKONSUL: 'DoctorCaller.JoinTelekonsul.Enabled',
    ENABLED_CLUSTER_BUTTON: 'DoctorCaller.AddCluster.Enabled',
    ENABLED_REFER_BUTTON: 'DoctorCaller.ReferPatient.Enabled',
    ENABLED_BUTTON_CHECKIN: 'DoctorCaller.CheckIn.Enabled',
    ACTION_REMIND_FINISH: 'DoctorCaller.RemindFinish.Save'
}

const PERMISSION_PEO = {
    // VISIT_BADGE_PEO: '/SINGLE QUEUE/PEO/SPV',
    // PASIEN_BADGE_PEO: '/SINGLE QUEUE/PEO/SPV',
    VISIT_BADGE_PEO: 'PEO.VisitBadge.Edit',
    PASIEN_BADGE_PEO: 'PEO.PatientBadge.Edit',
    SEARCH_PASIEN_PEO: 'PEO.SearchPasien.Enabled',
    DETAIL_TICKET_PEO: 'PEO.DetailTicket.Enabled',
    DELETE_VISIT_BADGE_PEO: 'PEO.VisitBadge.Delete',
    DELETE_PASIEN_BADGE_PEO: 'PEO.PatientBadge.Delete'
}


const PERMISSION_PAGE = {
    PAGE_UNIT_CALLER: 'UnitCaller.Page.View',
    PAGE_DOKTER_CALLER: 'DoctorCaller.Page.View',
    PAGE_PEO: 'PEO.Page.View',
    PAGE_DEVICE_CONFIG: 'DeviceConfig.Page.View',
    // PERMISSION_PAGE_UNIT_CALLER: '/SINGLE QUEUE/Unit Caller',
    // PERMISSION_PAGE_DOKTER_CALLER: '/SINGLE QUEUE/Dokter Caller',
    // PERMISSION_PAGE_PEO: '/SINGLE QUEUE/PEO',
    // PERMISSION_PAGE_DEVICE_CONFIG: '/SINGLE QUEUE/Device Config'
}

const ALL_PERMISSION = {
    ...PERMISSION_GLOBAL,
    ...PERMISSION_UNIT_CALLER,
    ...PERMISSION_PAGE,
    ...PERMISSION_PEO,
    ...PERMISSION_DOKTER_CALLER
}

export default ALL_PERMISSION